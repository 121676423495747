@-moz-document url-prefix() {
  fieldset {
    display: table-cell;
  }
}
.nardi-report h3 {
  margin-top: 30px;
}
.nardi-report tr {
  vertical-align: baseline;
}
@media print {
  th,
  td,
  p {
    font-size: 12pt;
  }
  nav.navbar,
  #footer {
    display: none;
  }
  #temperaments,
  #explanation,
  #expl-2,
  #references {
    page-break-before: always;
  }
  @page {
    margin: 1in, 0.5in, 0.5in;
  }
  h2 {
    color: green;
  }
}
