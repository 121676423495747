.framewrapper {
  min-width: 320px;
  height: 580px;
}

@supports (-webkit-overflow-scrolling: touch) {
  .framewrapper {
    min-width: 320px;
    min-height: 580px;
    height: auto;
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    padding: 0 5px;
  }
}

.framewrapper iframe {
  display: block;
  width: 100%;
}

.highlight {
  padding: 5px;
  border: 2px solid lightsalmon;
}