.survey-question .btn-toolbar {
  margin-top: 3em;
  margin-bottom: 3em;
}
.survey-question label {
  font-weight: 400;
}
.survey-question .question {
  min-height: 52.8px;
}
.survey-question input[type='radio'] {
  margin-right: 1em;
}
.survey-question .survey-options {
  min-height: 200px;
}
