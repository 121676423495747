.radio input[type="radio"] {
  margin-left: -30px !important;
}
.radio label {
  padding-left: 30px !important;
}
.radio label h4 {
  margin-top: 0;
}
.copy {
  font-size: 10px;
}
