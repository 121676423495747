.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
}
.btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  content: " ";
  display: table;
}
.dl-horizontal dd:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.pager:after,
.panel-body:after,
.modal-header:after,
.modal-footer:after {
  clear: both;
}
#footer {
  border-top: 1px solid #eeeeee;
}
#footer .navbar-default {
  margin-bottom: 0;
}
#footer .navbar-default .nav.social > li > a {
  padding: 10px 5px 0 0;
}
#footer .copy,
#footer .design-by {
  font-size: 10px;
}
@media only screen and (max-width: 767px) {
  #footer .navbar-default .nav.footer-nav {
    margin-top: 0;
    display: flex;
    justify-content: center;
  }
  #footer .navbar-default .nav.footer-nav > li > a {
    float: left;
    padding-top: 0;
  }
  #footer .navbar-default .nav.social {
    padding-bottom: 0;
    margin-top: 0;
    display: flex;
    justify-content: center;
  }
  #footer .navbar-default .nav.social > li {
    padding-left: 15px;
  }
  #footer .navbar-default .nav.social > li > a {
    float: left;
  }
  #footer .design-by,
  #footer .copy {
    text-align: center;
  }
}
