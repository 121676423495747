.pg-dash h3 {
  margin-top: 0.5em;
}
.pg-dash p {
  margin: 0 auto 0.5em;
}
.pg-dash .check {
  float: right;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
}
@media (max-width: 349px) {
  .pg-dash .check {
    display: none;
  }
}
.pg-dash .list-group {
  margin-top: 1.5em;
  margin-bottom: 0;
}
.pg-dash .pgid {
  text-transform: uppercase;
  margin-top: 1em;
}
@media (min-width: 992px) {
  .pg-dash {
    display: table;
  }
  .pg-dash .row {
    display: row;
  }
  .pg-dash .row .col-md-4,
  .pg-dash .row .col-md-8 {
    position: relative;
    display: table-cell;
    float: none;
  }
  .pg-dash .row .col-md-4 .well,
  .pg-dash .row .col-md-8 .well {
    margin-bottom: 30px;
  }
}
